<template>
  <div class="left-block blog-tags">
            <h3>Tags</h3>
            <a href="#" title="Beekeeping Hive">Beekeeping Hive</a>
            <a href="#" title="Smoker">Smoker</a>
            <a href="#" title="Jackate">Jackate</a>
            <a href="#" title="Beekeeping Brush">Beekeeping Brush</a>
          </div>
</template>

<script>
import { postsCollection } from "@/includes/firebase";

export default {
  name: "Tags",

  data() {
    return {
      tags: [],
      posts: [],
    };
  },
  methods: {
    readData() {
      this.posts = [];
      postsCollection.get().then((querySnapshot) => {
        // this.products = querySnapshot;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.posts.push(doc);
        });
      });
    },
  },
  mounted() {
    this.readData();
  },
};
</script>

<style scoped>
.blog-tags {
  margin-top: 30px;
}
.blog-tags h3 {
  margin-bottom: 20px;
}
.blog-tags a {
  display: inline-block;
  margin: 0px 10px 10px 0px;
  background-color: #f4f4f4;
  color: #383838;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 15px;
  font-weight: 400;
  border-radius: 3px;
}
.blog-tags a:last-child {
  margin-right: 0px;
}
.blog-tags a:hover {
  text-decoration: none;
  background-color: #ffcd00;
  color: #000;
}
</style>
