<template>
  <div class="col-md-6">
    <div class="blog-item wow fadeInUp">
      <div class="blog-item-bg">
        <img :src="post.data().images[0]" alt="Beekeeping Hive" />
      </div>
      <div class="blog-content">
        <h3>{{ post.data().title }}</h3>
        <div class="blog-post-by">
          <ul>
            <li><i class="fa fa-calendar"></i>{{humanDate(post.data().created)}}</li>
            <li><i class="fa fa-user"></i>By Admin</li>
          </ul>
        </div>
        <p class="max-lines" v-html="post.data().content"></p>
      </div>
      <div class="continue-read-blog">
        <router-link

          :to="{ name: 'article', params: { id: post.id } }"

          >Continue Reading</router-link
        >
      </div>
      <div class="blog-action">
        <div class="blog-action-holder">
          <a href="" title=""><i class="fa fa-thumbs-o-up"></i></a
          ><a href="" title=""><i class="fa fa-thumbs-o-down"></i></a>
          <a href="" title=""
            ><i class="fa fa-plus" @click.prevent="addNew"></i
          ></a>
          <a href="" title=""
            ><i class="fa fa-trash-o" @click.prevent="$emit('remove')"></i
          ></a>
        </div>
        <div class="blog-action-holder">
          <a href="" title=""><i class="fa fa-heart"></i></a
          ><a href="" title=""><i class="fa fa-share-alt"></i></a
          ><a href="" title=""><i class="fa fa-comments-o"></i></a>
        </div>
      </div>
    </div>
    <!-- Blog item end -->
  </div>
</template>

<script>
import { postsCollection } from "@/includes/firebase";

export default {
  name: "Post",
  props: ["post"],
  methods: {
    addNew() {
      // eslint-disable-next-line no-undef
      $("#post").modal("show");
    },

    trimStr(string) {
      const length = 250;
      // eslint-disable-next-line operator-linebreak
      const trimmedString =
        string.length > length
          ? `${string.substring(0, length - 3)}...`
          : string;
      return trimmedString;
    },
    humanDate(value) {
      return new Date(value).toDateString();
    },
  },

  mounted() {
    console.log(this.post.data());
  },
};
</script>

<style scoped>
.blog-item-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.max-lines {
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 6.8em;
  line-height: 1.8em;
}
</style>
