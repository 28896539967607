<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="Blog" />
  <!-- Breadcroumbs end -->

  <!-- post start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="row">
            <!-- post item start -->
            <Post
              v-for="post in posts"
              :key="post.id"
              :post="post"
              @remove="deletePost(post.id)"
            />
            <!-- post item end -->
          </div>

          <!-- post pagination start -->
          <div class="page-navigation">
            <nav aria-label="Page navigation example">
              <ul class="pagination mt40 justify-content-center">
                <li class="page-item disabled">
                  <a class="page-link" href="#" tabindex="-1"
                    ><i class="fa fa-angle-left" aria-hidden="true"></i
                  ></a>
                </li>
                <li class="page-item pagin-active">
                  <a class="page-link" href="#">1</a>
                </li>
                <li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li>
                <li class="page-item">
                  <a class="page-link" href="#"
                    ><i class="fa fa-angle-right" aria-hidden="true"></i
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- post pagination end -->
        </div>
        <!-- post sidebar start -->
        <div class="col-12 col-lg-3">
          <div class="left-block left-menu">
            <h3>Category</h3>
            <ul>
              <li class="active">
                <a href="" title="Beehive wood">Beehive wood</a>
              </li>
              <li><a href="" title="Beehive styropor">Beehive styropor</a></li>
              <li><a href="" title="Hive parts">Hive parts</a></li>
              <li><a href="" title="Nucleus hive">Nucleus hive</a></li>
              <li><a href="" title="Queen excluder">Queen excluder</a></li>
              <li><a href="" title="Frames">Frames</a></li>
              <li><a href="" title="Honey Processing">Honey Processing</a></li>
              <li><a href="" title="Honey extractors">Honey extractors</a></li>
              <li><a href="" title="Clothing">Clothing</a></li>
              <li><a href="" title="Queen Rearing">Queen Rearing</a></li>
              <li><a href="" title="Hive hardware">Hive hardware</a></li>
              <li><a href="" title="Beewax">Beewax</a></li>
              <li><a href="" title="Packaging">Packaging</a></li>
              <li><a href="" title="Feed for bees">Feed for bees</a></li>
            </ul>
          </div>
          <LatestPosts />
         <Tags />
        </div>
        <!-- post sidebar end -->
      </div>
    </div>
    <!-- Modal -->
    <div
      class="modal"
      id="post"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Add post</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- main product -->
              <div class="col-md-8">
                <div class="form-group">
                  <input
                    type="text"
                    placeholder="post Title"
                    v-model="post.title"
                    class="form-control"
                  />
                </div>

                <div class="form-group">
                  <textarea
                    name="content"
                    class="form-control"
                    placeholder="post Content"
                    v-model="post.content"
                    cols="30"
                    rows="10"
                  ></textarea>
                </div>
              </div>
              <!-- product sidebar -->
              <div class="col-md-4">
                <h4 class="display-6">post extra</h4>
                <hr />

                <div class="form-group">
                  <input
                    type="text"
                    v-on:keyup.enter="addTag"
                    placeholder="post tags"
                    v-model="tag"
                    class="form-control"
                  />
                  <div class="d-flex">
                    <p v-for="(tag, index) in post.tags" :key="index">
                      <span class="p-1 tag-wrap" @click="deleteTag">{{
                        tag
                      }}</span>
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label for="product_image">post Images</label>
                  <input
                    type="file"
                    @change="uploadImage"
                    class="form-control"
                  />
                </div>
                <div class="form-group d-flex">
                  <div
                    class="p-1"
                    v-for="(image, index) in post.images"
                    :key="image.id"
                  >
                    <div class="img-wrap">
                      <img style="width: 80px" :src="image" alt="" />
                      <span
                        class="delete-img"
                        @click="deleteImage(image, index)"
                        >X</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button @click="addPost()" type="button" class="btn btn-primary">
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- post End -->
  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import Post from "@/components/Post.vue";
import LatestPosts from "@/components/LatestPosts.vue";
import Tags from "@/components/Tags.vue";
import { db, storage, postsCollection } from "@/includes/firebase";

export default {
  name: "Blog",
  data() {
    return {
      posts: [],
      post: {
        title: null,
        content: null,
        images: [],
        tags: [],
        created: Date.now(),
        updated: Date.now(),
      },
      tag: null,
      activeItem: null,
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    Post,
    LatestPosts,
    Tags,
  },
  methods: {
    addNew() {
      this.reset();
      // eslint-disable-next-line no-undef
      $("#blog").modal("show");
    },
    reset() {
      this.post = {
        title: null,
        content: null,
        images: [],
        tags: [],
      };
    },
    addTag() {
      this.post.tags.push(this.tag);

      this.tag = "";
    },
    addPost() {
      postsCollection
        .add(this.post)
        .then((docRef) => {
          console.log("Document written with ID: ", docRef.id);
          this.readData();
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },
    readData() {
      this.posts = [];
      postsCollection.get().then((querySnapshot) => {
        // this.products = querySnapshot;
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          this.posts.push(doc);
        });
      });
      // eslint-disable-next-line no-undef
      $("#post").modal("hide");
    },
    // Remove tag from product
    deleteTag(tag, index) {
      this.post.tags.splice(index, 1);
    },
    deletePost(doc) {
      // eslint-disable-next-line no-alert
      if (window.confirm("Are you sure?")) {
        postsCollection
          .doc(doc)
          .delete()
          .then(() => {
            this.readData();
            console.log("Document successfully deleted!");
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }
    },
    uploadImage(e) {
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const timestamp = Date.now();
        const storageRef = storage.ref(`posts/${file.name}-${timestamp}`);
        const uploadTask = storageRef.put(file);

        // eslint-disable-next-line no-unused-vars
        uploadTask.on(
          "state_changed",
          // eslint-disable-next-line no-unused-vars
          (snapshot) => {
            // eslint-disable-next-line no-unused-vars
          },
          // eslint-disable-next-line no-unused-vars
          (error) => {
            // Handle unsuccessful uploads
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              this.post.images.push(downloadURL);
              console.log("File available at", downloadURL);
            });
          },
        );
      }
    },
    deleteImage(img, index) {
      const image = storage.refFromURL(img);
      this.post.images.splice(index, 1);
      image
        .delete()
        .then(() => {
          console.log("image deleted");
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error, "an error occurred");
        });
    },
  },

  // For updating on changes on real time
  watcher() {
    postsCollection.onSnapshot((querySnapshot) => {
      this.posts = [];
      querySnapshot.forEach((doc) => {
        this.posts.push(doc);
      });
    });
  },
  mounted() {
    this.readData();
  },
};
</script>
<style scoped>
.img-wrapp {
  position: relative;
}
.img-wrapp span.delete-img {
  position: absolute;
  top: -14px;
  left: -2px;
}
.img-wrapp span.delete-img:hover {
  cursor: pointer;
}
.tag-wrapp {
  position: relative;
}
.tag-wrap:hover {
  cursor: pointer;
}
.blog-tags {
  margin-top: 30px;
}
.blog-tags h3 {
  margin-bottom: 20px;
}
.blog-tags a {
  display: inline-block;
  margin: 0px 10px 10px 0px;
  background-color: #f4f4f4;
  color: #383838;
  text-transform: uppercase;
  font-size: 12px;
  padding: 12px 15px;
  font-weight: 400;
  border-radius: 3px;
}
.blog-tags a:last-child {
  margin-right: 0px;
}
.blog-tags a:hover {
  text-decoration: none;
  background-color: #ffcd00;
  color: #000;
}
</style>
